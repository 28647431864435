import React, { useEffect } from "react";
import { navigate } from "gatsby";

const Show = (props) => {
  useEffect(() => {
    navigate("/2023");
  });
  return <></>;
};

export default Show;
